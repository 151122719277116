.welcome-letter {
  width: 100%;
}

.welcome-letter-card {
  padding: 30px;
}

.welcome-letter-card .ql-editor {
  height: 300px !important;
}

.welcome_letter_tabs .ant-tabs-nav-wrap {
  width: 100%;
}

.welcome_letter_tabs .ant-tabs-nav-list {
  width: 100%;
}

.welcome_letter_tabs .ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
