@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
}

.full_container {
  max-width: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.site_content {
  min-height: calc(100vh - 70px);
}

.root,
.App,
.ant-layout {
  min-height: 100vh;
}

.App.admin {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.ql-container {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.welcome-letter .ql-container {
  height: 100% !important;
  max-height: 350px;
  min-height: 350px;
}

.ql-editor {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.welcome-letter .ql-editor {
  height: 100% !important;
  max-height: 350px;
  min-height: 350px;
}

.title-react-quill .ql-editor {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.title-react-quill .ql-container {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.hidden {
  display: none !important;
}

.ant-layout-content {
  padding: 40px;
}

.ant-layout-content.signin {
  padding: 0 !important;
  background-color: #f8f8f9;
}

.ant-input-number {
  width: 100%;
}

.preview-file {
  display: none;
}

.preview-file.has-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.preview-file.sponsor.has-preview {
  background-size: contain;
}

.preview-file img {
  width: auto;
  height: 90%;
}

.dragger.has-preview .ant-upload .ant-upload-btn .ant-upload-drag-container p {
  opacity: 0;
}

.admin-layout-content {
  background-color: #f8f8f9;
}

.dashboard-page {
  min-height: 100%;
  background: #fff;
  border-radius: 10px;
  max-height: calc(100vh - 144px);
  overflow: auto;
}

.dashboard-title-row {
  padding: 20px 20px 0 20px !important;
}

.dashboard-title {
  font-size: 20px;
  font-weight: bold;
}

.dashboard-content-row {
  padding: 20px !important;
  overflow: auto;
  min-height: calc(100vh - 290px);
}

.ant-avatar {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-layout-content::-webkit-scrollbar {
  width: 1em;
}

.admin-layout-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.admin-layout-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.ant-menu-item-group-title {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 1px solid #fff;
}
